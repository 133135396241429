import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  Form16: [],
};

//internally using immer lib (can create mutable state)
export const form16Slice = createSlice({
  name: "form16Data",
  initialState,
  reducers: {
    updateAllForm16: (state, action) => {
      state.Form16 = action.payload;
    },
  },
});

// this is for dispatch
export const { updateAllForm16 } = form16Slice.actions;

// this is for configureStore
export default form16Slice.reducer;
