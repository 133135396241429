
import ApiRoutes from "../configs/endpoints.config";
import HttpClient from "./index.api";
import { getTokenLocal } from "utils/localStorage.util";
const baseURL = process.env.REACT_APP_API_URL;

class Package extends HttpClient {
  constructor() {
    super(baseURL);
    this._initializeRequestInterceptor();
    this._initializeResponseInterceptor();
  }

  _initializeRequestInterceptor = () => {
    this.instance.interceptors.request.use((config) => {
      config.headers["Authorization"] = `Bearer ${getTokenLocal()}`;
      // config.headers["authkey"] = process.env.REACT_APP_AUTH_KEY;
      return config;
    });
  };

  _initializeResponseInterceptor = () => {
    this.instance.interceptors.response.use(
      (response) => {
        return response;
      },
      (response) => {
        return Promise.resolve(response);
      }
    );
  };

  AllPackageConfig = ApiRoutes.Package.All;
  AddPackageConfig = ApiRoutes.Package.AddPackage;
  GetPackageByIdConfig = ApiRoutes.Package.GetPackageById;
  editPackageConfig = ApiRoutes.Package.EditPackage;
  deletePackageConfig = ApiRoutes.Package.DeletePackage;

  getAllPackage = async () => {
    return this.instance({
      method: this.AllPackageConfig.Method,
      url: this.AllPackageConfig.Endpoint,
      headers: {},
      data: null,
    });
  };
  getPackageById = async (data) => {
    return this.instance({
      method: this.GetPackageByIdConfig.Method,
      url: this.GetPackageByIdConfig.Endpoint,
      headers: {},
      data: data,
    });
  };

  addPackage = async (data) => {
    return this.instance({
      method: this.AddPackageConfig.Method,
      url: this.AddPackageConfig.Endpoint,
      headers: {},
      data: data,
    });
  };
  editPackage = async (data) => {
    return this.instance({
      method: this.editPackageConfig.Method,
      url: this.editPackageConfig.Endpoint,
      headers: {},
      data: data,
    });
  };
  deletePackage = async (data) => {
    return this.instance({
      method: this.deletePackageConfig.Method,
      url: this.deletePackageConfig.Endpoint,
      headers: {},
      data: data,
    });
  };
}

export default Package;
